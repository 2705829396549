.option {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  border-radius: 8px;
  border: 2px solid #e2e2e2;
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover,
  &.active {
    box-shadow: 0px 4px 10px 0px #ccc;
  }

  &.active {
    border: 2px solid #6366f1;

    span {
      color: #6366f1;
    }
  }
}
