.container {
  padding: 96px 0;
  background: #f9fafc;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    .title {
      font-weight: 700;
      font-size: 48px;
      text-align: center;
    }
  }

  .cards {
    display: flex;
    row-gap: 40px;
    column-gap: 20px;
    margin-bottom: 40px;
  }

  .select {
    width: fit-content;
    margin-bottom: 30px;

    .label {
      margin-bottom: 4px;

      b {
        color: #6366f1;
      }
    }

    .dropdown {
      width: 100%;
    }
  }

  .verification {
    font-weight: 500;
    color: #888888;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 5vw 2vw;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px 20px 40px;

    .top {
      margin-bottom: 30px;

      .title {
        font-size: 36px;
      }
    }

    .verification {
      margin-bottom: 40px;
    }
  }
}
