.benefit {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;

    path {
      fill: #6ec63c;
    }
  }

  .text {
    color: inherit;
  }
}
