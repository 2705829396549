.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 440px;
  padding: 40px 0;
  box-sizing: border-box;
  border-radius: 10px !important;

  &.selected {
    border: 4px solid #6366f1;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
  }

  .top,
  .bottom {
    position: relative;
    padding: 0 40px;
  }

  .top {
    .divider {
      position: absolute;
      left: 0;
    }

    .benefits {
      padding-top: 20px;
    }
  }

  .type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .chip {
      font-weight: 700;
      font-size: 16px;
      padding: 4px 8px;
      margin-right: 10px;
    }
  }

  .billing {
    margin-bottom: 20px;

    .price {
      font-weight: 700;
      font-size: 48px;

      span {
        font-weight: 500;
        font-size: 16px;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .info {
    margin-bottom: 20px;
  }

  .words {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #f0f5f9;
    text-align: center;
  }

  .slider {
    margin-bottom: 40px;

    .MuiSlider-markLabel {
      &:first-child {
        left: 4%;
      }

      &:last-child {
        left: 90%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background: #f0f5f9;
  }

  .benefits {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    row-gap: 16px;
  }

  .submit {
    font-weight: 700;
    font-size: 16px;

    span {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    padding: 30px 0;

    .top,
    .bottom {
      padding: 0 30px;
    }

    .billing {
      .price {
        font-size: 42px;
      }
    }

    .slider {
      margin-bottom: 30px;
    }

    .benefits {
      margin-bottom: 30px;
    }
  }
}
