body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hero-text {
  font-weight: 200;
  font-family: "Roboto Slab";
  color      : #241c15;
  font-size  : 76px;
}

h1,
h2,
h3 {
  font-weight: 200;
  font-family: "Roboto Slab";
  color      : #241c15
}

h1 {
  font-size: 46px;
}


h2 {
  font-size: 30px;
}


h3 {
  font-size: 22px;
}



h4,
h5,
h6 {
  color: #241c15
}

.container {
  padding: 32px;
}

.gmail_quote {
  display: none;
}

.form-error-text {
  color      : red;
  font-size  : 13px;
  font-weight: 500;
}

blockquote[type="cite"] {
  display: none;
}

.workspace-selector-button {
  background-color: #fcf9f9;
  padding         : 12px 16px 12px 16px;
  margin          : 16px;
  border          : 1px solid #e5e3f0;
  border-radius   : 8px;
  cursor          : pointer;
}

.workspace-selector-button:hover {
  -webkit-filter: brightness(98%);
}

.integration-cell {
  cursor: pointer;
}

.integration-cell:hover {
  border    : 1px solid #CCC;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
}

.try-for-free-button {
  height          : 84px;
  text-align      : center;
  font-size       : 30px;
  font-weight     : 600;
  color           : white;
  padding-top     : 16px;
  padding-bottom  : 12px;
  border-radius   : 4px;
  background-image: linear-gradient(to right, #FF512F 0%, #F09819 51%, #FF512F 100%);
  transition      : 0.5s;
  background-size : 200% auto;
  cursor          : pointer;
  border          : 0px;

}


.try-for-free-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color              : #fff;
  text-decoration    : none;
  box-shadow         : 0px 0px 15px rgba(200, 0, 0, 0.45);
}

.form-input {
  height       : 40px;
  border       : none;
  border       : solid 1px #DDD;
  border-radius: 4px;
  font-size    : 15px;
  color        : #333;
  padding-left : 12px;
  padding-right: 12px;
  width        : 100%;
  margin-top   : 8px;
  box-shadow   : 2px 2px 3px rgba(0, 0, 0, 0.03);
  box-sizing   : border-box;
  font-family  : -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
}

.form-input:focus {
  outline           : none !important;
  -webkit-box-shadow: inset 0px 0px 0px 2px #6366f1;
  -moz-box-shadow   : inset 0px 0px 0px 2px #6366f1;
  box-shadow        : inset 0px 0px 0px 2px #6366f1;
}

.form-input-small:focus {
  outline           : none !important;
  -webkit-box-shadow: inset 0px 0px 0px 2px #6366f1;
  -moz-box-shadow   : inset 0px 0px 0px 2px #6366f1;
  box-shadow        : inset 0px 0px 0px 2px #6366f1;
}

.form-input-small {
  height       : 32px;
  border       : none;
  border       : solid 1px #DDD;
  border-radius: 4px;
  font-size    : 15px;
  color        : #333;
  padding-left : 8px;
  padding-right: 8px;
  width        : 100%;
  margin-top   : 8px;
  box-sizing   : border-box;

}

.form-textarea:focus {
  outline           : none !important;
  -webkit-box-shadow: inset 0px 0px 0px 2px #6366f1;
  -moz-box-shadow   : inset 0px 0px 0px 2px #6366f1;
  box-shadow        : inset 0px 0px 0px 2px #6366f1;

}

.form-textarea {
  border       : none;
  border       : solid 1px #DDD;
  border-radius: 4px;
  font-size    : 15px;
  color        : #333;
  padding-left : 12px;
  padding-right: 12px;
  width        : 100%;
  margin-top   : 8px;
  font-family  : -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding   : 8px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
}


.autoresize {
  resize: none;
}


.form-label {
  font-size  : 12px;
  font-weight: 700;
  margin-top : 24px;
  display    : inline-block;
  color      : #333;
}

.checkbox {
  width : 20px;
  height: 20px;
}

.cool-button {
  background-image: linear-gradient(to right, #6A5AF1 0%, #EA4595 51%, #6A5AF1 100%);
  border          : 0px;
  width           : 100%;
  height          : 54px;
  font-size       : 20px;
  font-weight     : 600;
  color           : white;
  border-radius   : 4px;
  cursor          : pointer;
  background-size : 200% auto;
  transition      : 0.5s;
}

.cool-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color              : #fff;
  text-decoration    : none;
  box-shadow         : 0px 0px 8px rgba(200, 0, 0, 0.25);
}

.cool-button.animate {
  position: relative;

  -webkit-animation-duration       : 2s;
  -webkit-animation-fill-mode      : forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name           : placeholderShimmer;
  -webkit-animation-timing-function: linear;
}


@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}


select {
  height       : 50px;
  border       : none;
  border       : solid 1px #ccc;
  border-radius: 4px;
  font-size    : 16px;
  color        : #333;
  padding-left : 8px;
  padding-right: 8px;
  width        : 100%;
  margin-top   : 8px;
  box-sizing   : border-box;
  border       : solid 1px #DDD;
  box-shadow   : 2px 2px 3px rgba(0, 0, 0, 0.03);
}

.form-error {
  color      : rgba(255, 40, 40);
  font-size  : 12px;
  font-weight: 600;
  margin-top : 8px;
}

.form-label-small {
  font-size  : 12px;
  font-weight: 600;
  margin-top : 12px;
  display    : inline-block;
  color      : #444;
}

.sidemenu-item-selected {
  color: #6366f1 !important;
}

.onboard {
  display       : flex;
  flex-direction: row;
  font-weight   : 500;
  padding-top   : 20px;
  padding-bottom: 16px;
  color         : "#333";
}

.onboard:hover {
  background: #F9F9F9;
}

.onboard.done {
  background-color: #f0fdf4;
  color           : #16A34A;
}

.onboard-icon {
  margin-left : 16px;
  margin-right: 16px;
  color       : #CCC
}


.onboard-icon.done {
  margin-left: 16px;
  color      : #16A34A;
}



.onboard-notdone {
  display       : flex;
  flex-direction: row;
  margin-left   : -16px;
  margin-right  : -16px;
  font-weight   : 500;
  padding-top   : 16px;
  padding-bottom: 16px;
}

.onboard-text {
  width: 440px;

}

.onboard-credits-text {
  color: "#CCC";
  width: 200px;
}

.firebase-emulator-warning {
  top     : 0;
  height  : 0px;
  width   : 800px !important;
  overflow: hidden;
  display : none;
}

.selected {
  background : rgb(226 232 240);
  font-weight: 600 !important;
}

.MuiSkeleton-rectangular {
  border-radius   : 8px;
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.contact-cell:hover {
  background: #F9FAFC;
}

.logo-icon {
  background-image: url(./images/logo_icon.png);
  width           : 50px;
  height          : 50px;
  background-size : cover;
}

.logo-dark {
  background-image: url(./images/logo_dark.png);
  width           : 238px;
  height          : 30px;
  background-size : cover;
}

.contact-cell .small-icon-button {
  color        : #EFEFEF;
  border-radius: 4px;
  padding      : 5px;
}

.contact-cell:hover .small-icon-button {
  color     : #CCC;
  background: white;
  transition: background .3s, box-shadow .3s, color .3s;

}

.contact-cell:hover .small-icon-button:hover {
  color             : #666;
  background        : white;
  -webkit-box-shadow: inset 0px 0px 0px 1px #EEE;
  -moz-box-shadow   : inset 0px 0px 0px 1px #EEE;
  box-shadow        : inset 0px 0px 0px 1px #EEE;

}


/* Uploader */

#form-file-upload {
  height    : 16rem;
  width     : 100%;
  max-width : 100%;
  text-align: center;
  position  : relative;
  cursor    : pointer;
}

#input-file-upload {
  display: none;
  cursor : pointer;

}

#label-file-upload {
  cursor          : pointer;
  height          : 100%;
  display         : flex;
  align-items     : center;
  justify-content : center;
  border-width    : 2px;
  border-radius   : 0.25rem;
  border-color    : #cbd5e1;
  background-color: #f8fafc;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke=' %23CCCCCC' stroke-width='3' stroke-dasharray='5%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

#label-file-upload.drag-active {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke=' %236366f1' stroke-width='3' stroke-dasharray='5%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.upload-button {
  cursor          : pointer;
  padding         : 0.25rem;
  font-size       : 1rem;
  border          : none;
  font-family     : 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;

}

#drag-file-element {
  position     : absolute;
  width        : 100%;
  height       : 100%;
  border-radius: 1rem;
  top          : 0px;
  right        : 0px;
  bottom       : 0px;
  left         : 0px;
}

.drag-active {
  color: #6366f1;

}

/*  Data grid */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

/*  Data grid */
.MuiDataGrid-root .MuiDataGrid-cell {
  cursor: pointer;
}

.no-border-notched {
  border-color: transparent !important;
}