.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9fafc;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 96px 40px;

  .top,
  .content {
    max-width: 720px;
  }

  .top {
    width: fit-content;
    margin: 0 0 96px;

    .title {
      font-weight: 700;
      font-size: 48px;
      margin-bottom: 10px;
      text-align: center;
    }

    .subtitle {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
      color: #888888;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;
      column-gap: 16px;
      margin-bottom: 40px;
    }

    .submit {
      border-radius: 4px;

      &:disabled {
        cursor: not-allowed;
        pointer-events: visible;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }
}
